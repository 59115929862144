import React from 'react';
import { useSelector } from 'react-redux';
import useActiveSection from '../utils/useActiveSection';
import { AiFillLinkedin, AiFillGithub, AiOutlineMail } from 'react-icons/ai';
import CVIcon from '../icons/CVIcon';
import '../styles/sidebar.css';

function Sidebar(props) {
  const theme = useSelector((state) => state.theme.mode);
  const { hero, about, projects, contact, scrollToSection } = props;
  const activeSection = useActiveSection(props);

  return (
    <div className={`sidebar ${theme === 'dark' ? 'sidebar-dark' : ''}`}>
      <SidebarName onClick={() => scrollToSection(hero)} />

      <ul>
        <SidebarItem
          active={activeSection === 'hero'}
          onClick={() => scrollToSection(hero)}
        />
        <SidebarItem
          active={activeSection === 'about'}
          onClick={() => scrollToSection(about)}
        />
        <SidebarItem
          active={activeSection === 'projects'}
          onClick={() => scrollToSection(projects)}
        />
        <SidebarItem
          active={activeSection === 'contact'}
          onClick={() => scrollToSection(contact)}
        />
      </ul>

      <SidebarIcons />
    </div>
  );
}

function SidebarName(props) {
  const { onClick } = props;
  return (
    <h2 className='sidebar-name' onClick={onClick}>
      <span>CALEB</span>
      <span>KIM.</span>
    </h2>
  );
}

function SidebarItem(props) {
  const { active, onClick } = props;
  return (
    <li onClick={onClick}>
      <div className={`pagination ${active ? 'active' : ''}`}></div>
    </li>
  );
}

function SidebarIcons() {
  const email = 'caleb.kim1@outlook.com';
  const size = 28;

  return (
    <div className='sidebar-icons'>
      <a href='https://www.linkedin.com/in/caleb-kim1' target='_blank'>
        <div className='icon'>
          <AiFillLinkedin size={size} />
        </div>
      </a>
      <a href='https://github.com/Swhag' target='_blank'>
        <div className='icon'>
          <AiFillGithub size={size} />
        </div>
      </a>
      <a
        href='https://drive.google.com/file/d/1XjeV5qWXkH4z4s_FDPknXW3OmV5G7vvj/view?usp=sharing'
        target='_blank'
      >
        <div className='icon'>
          <CVIcon size={size} />
        </div>
      </a>

      <a href={`mailto:${email}`}>
        <div className='icon'>
          <AiOutlineMail size={size} />
        </div>
      </a>

      <div className='line'></div>
    </div>
  );
}

export default Sidebar;
